<template>
  <div class="main-container">
    <div class="relative z-10 py-32">
      <p
        class="tracking-wider text-white font-bold text-xl text-center md:text-3xl md:text-left md:ml-20 mb-16"
      >
        STAF MANAJEMEN
      </p>
      <div
        class="container px-8 mx-auto flex flex-wrap items-center justify-center gap-8"
      >
        <div
          class="max-w-xs text-left shadow-xl rounded bg-white"
          v-for="(item, key) in staffs"
          :key="key"
        >
          <img
            class="mb-4 w-full mx-auto"
            :src="item.thumbnail_url_full"
            alt=""
          />
          <div class="p-4">
            <p class="font-bold">{{ item.name }}</p>
            <span class="text-gray-500 mb-4 block">{{ item.jobdesk }}</span>
            <p>{{ item.description }}</p>
          </div>
        </div>

        <!-- Rollback -->
        <div v-if="staffs.length === 0">
          <svg
            role="img"
            width="340"
            height="84"
            aria-labelledby="loading-aria"
            viewBox="0 0 340 84"
            preserveAspectRatio="none"
          >
            <title id="loading-aria">Loading...</title>
            <rect
              x="0"
              y="0"
              width="100%"
              height="100%"
              clip-path="url(#clip-path)"
              style="fill: url('#fill')"
            ></rect>
            <defs>
              <clipPath id="clip-path">
                <rect x="0" y="0" rx="3" ry="3" width="67" height="11" />
                <rect x="76" y="0" rx="3" ry="3" width="140" height="11" />
                <rect x="127" y="48" rx="3" ry="3" width="53" height="11" />
                <rect x="187" y="48" rx="3" ry="3" width="72" height="11" />
                <rect x="18" y="48" rx="3" ry="3" width="100" height="11" />
                <rect x="0" y="71" rx="3" ry="3" width="37" height="11" />
                <rect x="18" y="23" rx="3" ry="3" width="140" height="11" />
                <rect x="166" y="23" rx="3" ry="3" width="173" height="11" />
              </clipPath>
              <linearGradient id="fill">
                <stop offset="0.599964" stop-color="#d7d7d7" stop-opacity="1">
                  <animate
                    attributeName="offset"
                    values="-2; -2; 1"
                    keyTimes="0; 0.25; 1"
                    dur="2s"
                    repeatCount="indefinite"
                  ></animate>
                </stop>
                <stop offset="1.59996" stop-color="#ecebeb" stop-opacity="1">
                  <animate
                    attributeName="offset"
                    values="-1; -1; 2"
                    keyTimes="0; 0.25; 1"
                    dur="2s"
                    repeatCount="indefinite"
                  ></animate>
                </stop>
                <stop offset="2.59996" stop-color="#d7d7d7" stop-opacity="1">
                  <animate
                    attributeName="offset"
                    values="0; 0; 3"
                    keyTimes="0; 0.25; 1"
                    dur="2s"
                    repeatCount="indefinite"
                  ></animate>
                </stop>
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
    </div>
    <!-- divider -->
    <div class="custom-shape-divider-bottom-1609602518">
      <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 120"
        preserveAspectRatio="none"
      >
        <path
          d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
          opacity=".25"
          class="shape-fill"
        ></path>
        <path
          d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
          opacity=".5"
          class="shape-fill"
        ></path>
        <path
          d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
          class="shape-fill"
        ></path>
      </svg>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
export default {
  data() {
    return {
      staffs: [],
    };
  },
  methods: {
    fetchStaff() {
      Axios.get("api/staff").then((response) => {
        this.staffs = response.data.staff;
      });
    },
  },
  mounted() {
    this.fetchStaff();
  },
  name: "StafList",
};
</script>

<style lang="scss" scoped>
.main-container {
  @apply pb-2 p-2 z-10 relative bg-gradient-to-b from-green-600 to-gray-200;
}

/** divider */
.custom-shape-divider-bottom-1609602518 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1609602518 svg {
  position: relative;
  display: block;
  width: calc(109% + 1.3px);
  height: 165px;
}

.custom-shape-divider-bottom-1609602518 .shape-fill {
  fill: #e5e7eb;
}

/** For mobile devices **/
@media (max-width: 767px) {
  .custom-shape-divider-bottom-1609602518 svg {
    width: calc(132% + 1.3px);
    height: 131px;
  }
}
</style>